import { createBreakpoints } from "@chakra-ui/theme-tools";

export const breakpointsMapping = {
  xs: "30em", // 480px
  sm: "40em", // 640px
  md: "60em", // 960px
  navbar: "73em", // 1168px
  lg: "75em", // 1200px
  xl: "90em", // 1440px
  "2xl": "96em", // 1536px
  "3xl" : "100em" // 1600px
};

const breakpoints = createBreakpoints(breakpointsMapping);

export default breakpoints;
